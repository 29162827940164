<template>
    <div class="side-bar" :class="ouvert ? 'nav-hide' : ''">
        <div class="liste-signets">
            <template v-if="signets.length > 0">
                <div v-for="(signet, index) in signets" :key="'signet-link-' + index" class="signet-container">
                    <v-btn text block small class="side-bar-signet" :key="'signet-link-' + index" @click="allerAuSignet(signet)">
                        {{ signet.titre }}
                    </v-btn>
                </div>
            </template>
            <template v-else>
                <div class="side-bar-signet liste-vide">
                    Aucun sommaire détecté
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        signets: {
            type: Array,
            default: () => []
        },
        ouvert: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        allerAuSignet (signet) {
            this.$emit('gotopage', signet.page);
            this.$emit('close');
        }
    }
}
</script>

<style lang="scss" scoped>
    .side-bar {
        overflow-x: hidden;
        background-color: rgba(33,33,33, 0.6);
        transition: left 0.3s linear;
        &:not(.nav-hide) {
            left: -300px !important;
        }

        //transition: left .5s, transform .5s;
        position: fixed;
        //top: $headheight;
        left: 0;
        z-index: 50;
        display: flex;
        flex-direction: column;
        width: 300px;
        height: calc(100% - 20px);
        border-right: 0 solid $lightgray;
        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.15);

        & .liste-signets {
            margin-top: 30px;
            & .side-bar-signet {
                display: block;
                height: auto;
                color: #ccc;
                &:not(.liste-vide) {
                    cursor: pointer;
                }
                &:hover {
                    color:#fff;
                }
            }
        }
    }
</style>
<style lang="scss">
    .side-bar-signet {

        & .v-btn__content {
            display: block;
            width: 100%;
            white-space: normal;
            text-align: left;
            padding: 10px 0;
        }
    }
</style>