<template>
    <div class="liseuse" v-if="ready">
        <liseuse-recherche :contenu="texte" v-if="texte && rechercheVisible" v-on:gotopage="allerPage" @close="fermeRecherche"></liseuse-recherche>
        <liseuse-sommaire :signets="bookmarks" :ouvert="sommaireVisible" v-on:gotopage="allerPage" @close="fermeSommaire"></liseuse-sommaire>
        <liseuse-aide v-model="aideVisible" />
        <div :class="{ 'has-mouse': hasMouse }" class="flipbook-composant"  @touchstart="hasMouse = false">
            <flipbook
                class="flipbook"
                :pages="pagesFlipbook"
                :pagesHiRes="pagesHiRes"
                :startPage="pageNum"
                :zooms="zoomsPossibles"
                :gloss="brillance"
                :singlePage="pagesAffichees === 1"
                v-slot="flipbook"
                ref="objflipbook"
                @flip-left-start="onFlipLeftStart"
                @flip-left-end="onFlipLeftEnd"
                @flip-right-start="onFlipRightStart"
                @flip-right-end="onFlipRightEnd"
                @zoom-start="onZoomStart"
                @zoom-end="onZoomEnd"
            >
                <div class="bandeau-action">
                    <div class="retour" @click="retour">
                        <v-icon class="btn" dark :disabled="peutRetour">{{ mdiArrowLeft }}</v-icon>
                        <span>Retour</span>
                    </div>
                    <div class="action-bar">
                        <v-icon
                            @click="flipbook.flipLeft"
                            dark
                            :disabled="!flipbook.canFlipLeft"
                            class="btn"
                            size="36px"
                        >{{ mdiChevronLeft }}</v-icon>
                        <span class="page-num">Page {{ flipbook.page }} / {{ flipbook.numPages }}</span>
                        <v-icon
                            @click="flipbook.flipRight"
                            :disabled="!flipbook.canFlipRight"
                            dark
                            class="btn"
                            size="36px"
                        >{{ mdiChevronRight }}</v-icon>
                    </div>
                    <div class="flipbook-actions">
                        <a href="#" id="sommaire-btn" title="Afficher le sommaire" @click.prevent="afficherSommaire"><v-icon dark>{{ mdiFormatListBulleted }}</v-icon></a>
                        <a href="#" id="search-btn" title="Afficher la recherche" @click.prevent="recherche"><v-icon dark>{{ mdiMagnify }}</v-icon></a>
                        <a href="#" id="help-btn" title="Afficher l'aide" @click.prevent="aide"><v-icon dark>{{ mdiHelpCircle }}</v-icon></a>
                    </div>
                </div>
            </flipbook>
        </div>
        <div v-show="false" ref="eventReferrer"></div>
    </div>
</template>

<script>

import Flipbook from "flipbook-vue/dist/flipbook.es";
import {mapActions, mapGetters} from "vuex";
import Api from "../api/api";
import LiseuseRecherche from "../components/liseuse/recherche/LiseuseRecherche";
import LiseuseSommaire from "../components/liseuse/sommaire/LiseuseSommaire";
import LiseuseAide from "../components/liseuse/aide/LiseuseAide";
import { mdiHelpCircle, mdiMagnify, mdiFormatListBulleted, mdiChevronRight, mdiChevronLeft, mdiArrowLeft } from "@mdi/js";

export default {
    name: "Liseuse",
    components: {
        Flipbook,
        LiseuseRecherche,
        LiseuseSommaire,
        LiseuseAide
    },
    data() {
        return {
            getInfosURL: process.env.VUE_APP_API_URL + '/flipbook/infos/',
            getPageImageURL: process.env.VUE_APP_API_URL + '/flipbook/page/',
            articleId: null,
            bookmarks: [],
            nbpages: 0,
            tailles: {},
            texte: {},
            pages: [],
            pagesHiRes: [],
            zoomsPossibles: [1],
            hasMouse: true,
            pageNum: null,
            rechercheVisible: false,
            notesAffichees: false,
            sommaireVisible: false,
            notesVisibles: true,
            aideVisible: false,
            brillance: 0.9,
            ready: false,
            flipbook: null,
            origin: null,
            nbRetours: 1,

            mdiHelpCircle,
            mdiMagnify,
            mdiFormatListBulleted,
            mdiChevronRight,
            mdiChevronLeft,
            mdiArrowLeft
        };
    },
    computed: {
        ...mapGetters([
            'bearer'
        ]),
        peutRetour () {
            return window.history.length > this.nbRetours;
        },
        pagesAffichees () {
            return this.flipbook ? this.flipbook.displayedPages : 2
        },
        pagesFlipbook () {
            let pages = [null];
            for (let i = 1; i <= this.nbpages; i++) {
                pages.push(this.getPageImageURL + `${this.articleId}-${i}?Bearer=${this.bearer}&t=${this.timestampQuery}`);
            }

            return pages;
        },
        pageEnCours () {
            if (!this.flipbook) return 1;
            return this.flipbook.page;
        },
        pagesVisibles () {
            return this.flipbook && this.pagesAffichees === 2 && this.pageEnCours > 1 && this.pageEnCours < this.nbpages
                ? [
                this.flipbook.firstPage,
                this.flipbook.firstPage + 1,
                ] : [
                this.pageEnCours
                ]
        }
    },
    methods: {
        ...mapActions([
            'setNavBar'
        ]),
        retour () {
            if (this.peutRetour)
                this.$router.go(-1 * this.nbRetours);
        },
        toggleNote () {
            this.notesVisibles = !this.notesVisibles;
        },
        tentePage(n) {
            if (n > 1 && n % this.pagesAffichees) n -=1;
            return Math.max(Math.min(this.pagesFlipbook.length-1, n), 0);
        },
        afficherSommaire () {
            this.sommaireVisible = !this.sommaireVisible;
        },
        recherche () {
            this.rechercheVisible = true;
        },
        fermeRecherche() {
            this.rechercheVisible = false;
        },
        fermeSommaire () {
            this.sommaireVisible = false;
        },
        aide () {
            this.aideVisible = true;
        },
        allerPage (page) {
            this.flipbook.goToPage(this.tentePage(page));
        },
        recupereInfos () {
            return Api.get(this.getInfosURL + this.articleId)
                .then(infos => {
                    this.bookmarks = Object.assign([], infos.bookmarks);
                    this.nbpages = infos.nbpages;
                    this.tailles = Object.assign({}, infos.tailles);
                    this.texte = infos.texte;
                });
        },
        restoreArticleId () {
            this.articleId = Number(this.$route.params.id) ?? null;
        },
        //fonction d'origine du flipbook github
        onFlipLeftStart () {
            //return console.log('flip-left-start', page);
        },
        onFlipLeftEnd (page) {
            //console.log('flip-left-end', page);
            this.nbRetours += 1;
            return window.location.hash = '#' + page;
        },
        onFlipRightStart () {
            //return console.log('flip-right-start', page);
        },
        onFlipRightEnd (page) {
            //console.log('flip-right-end', page);
            this.nbRetours += 1;
            return window.location.hash = '#' + page;
        },
        onZoomStart() {
            //return console.log('zoom-start', zoom);
        },
        onZoomEnd() {
            //return console.log('zoom-end', zoom);
        },
        setPageFromHash () {
            const n = parseInt(window.location.hash.slice(1), 10);
            if (isFinite(n)) { return this.pageNum = this.tentePage(n); }
        },
        keyDown (e) {
            // Correctif VS - 07-03-22
            // Si l'eventReferrer (balise invisible) n'est pas defini, ca veut dire que la vue n'existe plus
            // et que les event listeners ont mal ete detaches de window, ce qui ne devrait pas arriver
            // Comme si l'on revient sur cette vue ce sera une nouvelle instance, on peut detacher l'event
            // de maniere sure sans avoir d'effet indesirable
            if (this.$refs.eventReferrer === undefined) {
                window.removeEventListener('keydown', this.keyDown.bind(this));
                return;
            }

            let prevent = false;
            switch(e.code) {
                case 'KeyF':
                    if(e.ctrlKey) this.recherche();
                    prevent = true;
                    break;
                case 'Tab':
                    this.afficherSommaire();
                    prevent = true;
                    break
                case 'Home':
                    this.flipbook.goToPage(1);
                    break;
                case 'End':
                    this.flipbook.goToPage(this.flipbook.numPages);
                    break;
                case 'PageUp':
                    this.flipbook.goToPage(Math.max(1, this.flipbook.currentPage - 10));
                    break;
                case 'PageDown':
                    this.flipbook.goToPage(Math.min(this.flipbook.numPages, this.flipbook.currentPage + 10));
                    break;
                case 'ArrowLeft':
                    if (this.flipbook.canFlipLeft) this.flipbook.flipLeft();
                    break;
                case 'ArrowRight':
                    if (this.flipbook.canFlipRight) this.flipbook.flipRight();
                    break;
            }
            if (prevent) {
                e.preventDefault();
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => { vm.origin = from })
    },
    mounted() {
        this.restoreArticleId();
        //this.setNavBar(false);
        this.recupereInfos().then(() => {
            this.setPageFromHash();
            this.ready = true;
            this.$nextTick(() => {
                this.flipbook = this.$refs.objflipbook;
                window.addEventListener('keydown', this.keyDown.bind(this));
                window.addEventListener('hashchange', this.setPageFromHash.bind(this));
            });

        }).catch(() => {
            this.setNavBar(true);
            window.history.length > 2 ? this.$router.go(-1) :  this.$router.push('/');
        });
    },
    beforeDestroy() {
        //this.setNavBar(true);
        window.removeEventListener('keydown', this.keyDown.bind(this));
        window.removeEventListener('hashchange', this.setPageFromHash.bind(this));
    }
}
</script>

<style lang="scss">

.liseuse {
    position: relative;
    width: 100%;
    background-size: contain;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & .toolkipflp {
        width:auto;
        position: absolute;
        padding:0;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        color: #fff;
        background-color: rgba(0,0,0, 1);
        font-size: 1em;
        border-bottom: 1px solid #333;
        border-bottom-left-radius: 4px;
        border-left: 1px solid #333;
        user-select: none;
        cursor: default;
        z-index: 10;
        >a {
            padding: 0 5px;
            margin: 0;
            color: inherit;
        }
        >a:hover {
            color: #ccc;
            background-color: #333;
        }
    }
    .flipbook-composant {
        padding:0;
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width:90vw;
        margin: auto;

        color: #ccc;
        //background: #ccc url('/img/flipbook/background.jpg') repeat;
        & .flipbook {
            width: 100%;
            height: 100%;
            display: block;
            &>div.viewport {
                overflow:inherit;
                margin-bottom:25px;
            }
            & .bandeau-action {
                display: flex;
                flex-direction: row;
                justify-content:space-around;
                margin: 3px;
                .action-bar, .retour, .flipbook-actions {
                    & > * {
                        margin: 0 2px;
                    }
                    background-color: #000;
                    height: 30px;
                    padding: 10px 10px;
                    margin: 2px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & a {
                    text-decoration: none;
                }
                & .retour {
                    cursor: pointer;
                    & i {
                        margin-right:8px;
                    }
                }
                .action-bar {
                    width:230px;
                    .page-num {
                        font-size: 1.1em;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    .btn-disabled {
                        color: #666;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

a {
    color: inherit;
}

.has-mouse {
    & .btn:hover {
        color: #ccc;
        filter: drop-shadow(1px 1px 5px #000);
        cursor: pointer;
    }
    & .btn:active {
        filter: none !important;
    }
}
</style>
<style lang="scss">
.flipbook .viewport {
    height: calc(100vh - 130px) !important;
    width: 100%;
}
.bounding-box {
    box-shadow: 0 0 20px #000;
}
</style>