import { render, staticRenderFns } from "./LiseuseAide.vue?vue&type=template&id=f80a1302&scoped=true&"
import script from "./LiseuseAide.vue?vue&type=script&lang=js&"
export * from "./LiseuseAide.vue?vue&type=script&lang=js&"
import style0 from "./LiseuseAide.vue?vue&type=style&index=0&id=f80a1302&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f80a1302",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
