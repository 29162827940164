<template>
    <div id="help-desktop" class="help" v-if="value">
        <div class="help-content">
            <h2>Aide à la prise en main</h2>
            <a class="close" href="#" id="desktop-close" @click="close">&times;</a>
            <div class="content">
                <div>
                    <div class="help-illustration">
                        <kbd><v-icon>{{ mdiArrowLeft }}</v-icon></kbd>
                        <kbd><v-icon>{{ mdiArrowRight }}</v-icon></kbd>
                    </div>
                    <span class="help-text">Pour faire tourner les pages</span>
                </div>
                <div>
                    <div class="help-illustration">
                        <kbd>TAB</kbd>
                    </div>
                    <span class="help-text">Pour faire apparaître les signets</span>
                </div>
                <div>
                    <div class="help-illustration">
                        <span><kbd>Ctrl</kbd> + <kbd>F</kbd></span>
                    </div>
                    <span class="help-text">Pour faire apparaître la recherche</span>
                </div>
                <div class="no-image">
                    <div class="help-illustration">
                        <kbd>&#8598;</kbd>
                    </div>
                    <span class="help-text">Pour revenir à la première page</span>
                </div>
                <div class="no-image">
                    <div class="help-illustration">
                        <kbd>Fin</kbd>
                    </div>
                    <span class="help-text">Pour aller à la dernière page</span>
                </div>
                <div>
                    <div class="help-illustration">
                        <kbd><v-icon>{{ mdiChevronDoubleUp }}</v-icon></kbd>
                        <kbd><v-icon>{{ mdiChevronDoubleDown }}</v-icon></kbd>
                    </div>
                    <span class="help-text">Pour faire avancer/reculer de 10 pages</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiArrowLeft , mdiArrowRight, mdiChevronDoubleUp, mdiChevronDoubleDown } from "@mdi/js";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mdiArrowLeft,
            mdiArrowRight,
            mdiChevronDoubleUp,
            mdiChevronDoubleDown
        }
    },
    methods: {
        close () {
            this.$emit('input', false);
        }
    }
}
</script>

<style lang="scss" scoped>

kbd {
    display: inline-block;
    margin: 0 .1em;
    padding: .1em .6em;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
    line-height: 1.4;
    color: #242729;
    text-shadow: 0 1px 0 #FFF;
    background-color: #e1e3e5;
    border: 1px solid #adb3b9;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(12,13,14,0.2), 0 0 0 2px #FFF inset;
    white-space: nowrap;
}

.help {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    z-index: 1002;
}
.help:target {
    visibility: visible;
    opacity: 1;
}

.help-content {
    margin: auto;
    padding: 20px;
    background: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    width: auto;
    height: 100%;
    position: relative;
    transition: all 5s ease-in-out;
}

.help-content h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.help-content .close {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 48px;
    height: 48px;
    transition: all 200ms;
    font-size: 2rem;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #BA4536;
    border-radius: 10px;
    padding: 0 0.3em;
}
.help-content .close:hover {
    color: #D8065F;
}
.help-content .content {
    overflow: auto;
}
.help-content .content > div {
    height: 5em;
    display: flex;
}
.help-illustration > img {
    height: 5em;
    width: 5em;
}
.no-image {
    display: flex;
}
.no-image > span {
    align-self: center;
    margin-left: 1.5em;
}
.help-illustration {
    vertical-align: middle;
    display: inline-flex;
}
.help-illustration > kbd, .help-illustration > span {
    align-self: center;
    white-space: nowrap;
}
.help-illustration > kbd > img {
    height: 2em;
    padding-top: 0.3em;
}
.help-text {
    font-family: Tahoma, Arial, sans-serif;
    font-weight: bold;
    display: inline-block;
    align-self: center;
    margin-left: 1em;
}
</style>